import * as React from 'react'
import { Script } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/roboto'
import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => (
      <a data-location='external' {...props}>
        {props.children}
      </a>
    )}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <link href={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/css/bidjs--full.min.css`} rel='stylesheet' type='text/css' />
      <Script id='bid-js-script' src={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs.min.js`} />
    </>
  )
}
